import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/apps/drm-frontend/src/app/Main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/home/apps/drm-frontend/src/server/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/home/apps/drm-frontend/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-_d66b0e636ec105855d734cc55762dc9a/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Major_Mono_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--major-mono-display\"}],\"variableName\":\"majorMonoDisplay\"}");
